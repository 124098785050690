import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { decrypt, encrypt } from '../security/state-cypher';

@Injectable()
export class StorageService {
  private readonly isProduction = environment.production;

  set<T>(key: string, value: T): void {
    const storageValue = this.isProduction
      ? encrypt(value)
      : JSON.stringify(value);

    localStorage.setItem(key, storageValue);
  }

  get<T>(key: string): T | null {
    try {
      const value = localStorage.getItem(key);

      if (!value) {
        return null;
      }

      return this.isProduction ? decrypt(value) : (JSON.parse(value) as T);
    } catch {
      this.clear();

      return null;
    }
  }

  exists(key: string): boolean {
    return !!localStorage.getItem(key);
  }

  remove(key: string): void {
    if (this.exists(key)) {
      localStorage.removeItem(key);
    }
  }

  clear(): void {
    localStorage.clear();
  }

  length(): number {
    return localStorage.length;
  }
}
