import { KibanaRegisterData } from '../../models/kibana.model';

export class SaveKibanaLogAction {
  static readonly type = '[Kibana] Save Kibana Log';
  constructor(public payload: KibanaRegisterData) {}
}

export class SetKibanaDataAction {
  static readonly type = '[Kibana] Set Kibana Data';
  constructor(public payload: Record<string, any>) {}
}
