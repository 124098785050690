import { SaveAnswerNpsSurveyModel } from './survey.model';

export class StartTookNpsTimeAction {
  static readonly type = '[Survey] Start took nps time';
  constructor(public maxDurationInMinutes?: number) {}
}

export class SetLessonsRegisteredPagesViewedAction {
  static readonly type = '[Survey] Set lessons registered pages viewed';
  constructor(public lessonId: number) {}
}

export class SaveAnswerNpsSurveyAction {
  static readonly type = '[Survey] Save answer nps survey';
  constructor(public payload: SaveAnswerNpsSurveyModel) {}
}
