<div
  class="fixed inset-0 w-screen h-screen bg-black/75 flex flex-col justify-center items-center z-[9999]"
>
  <img
    class="w-24 h-24 animate-pulse object-cover"
    src="assets/images/logo-liv.webp"
    alt=""
  />

  <div class="mt-6 text-center">
    <h3 *ngIf="title" class="text-xl font-bold text-neutral-hight-pure-light">
      {{ title }}
    </h3>
    <p class="text-base text-neutral-hight-pure-light">Aguarde um momento.</p>
  </div>
</div>
