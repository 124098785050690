import { Injectable, OnDestroy } from '@angular/core';
import {
  Observable,
  takeUntil,
  Subject,
  map,
  animationFrameScheduler,
  delay,
  timer,
  takeWhile,
  concatMap,
  of,
  filter
} from 'rxjs';

@Injectable()
export class TimerService implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // TODO: Return remaining time in seconds if maxDurationInMilliseconds is provided
  startTimer(
    internalInMilliseconds: number,
    maxDurationInMilliseconds?: number,
    delayInMilliseconds?: number
  ): Observable<number> {
    const delayMs = delayInMilliseconds ?? 0;
    const maxDurationInSeconds = maxDurationInMilliseconds
      ? maxDurationInMilliseconds / 1000
      : 0;

    const isVisible = () => document.visibilityState === 'visible';

    return timer(0, internalInMilliseconds, animationFrameScheduler).pipe(
      filter(isVisible),
      concatMap((count) =>
        of(count).pipe(delay(delayMs, animationFrameScheduler))
      ),
      map((count) => {
        const currentCount = count + 1;

        return delayMs
          ? currentCount * (delayMs / internalInMilliseconds)
          : currentCount;
      }),
      takeUntil(this.destroy$),
      takeWhile((counter) =>
        this.isWithinMaxDuration(counter, maxDurationInSeconds)
      )
    );
  }

  private isWithinMaxDuration(
    counter: number,
    maxDurationInSeconds: number
  ): boolean {
    return !maxDurationInSeconds || counter <= maxDurationInSeconds;
  }
}
