import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  StateToken,
  Store
} from '@ngxs/store';

import { tap } from 'rxjs';
import { TimerService } from '../../services/timer.service';
import {
  SaveAnswerNpsSurveyAction,
  SetLessonsRegisteredPagesViewedAction,
  StartTookNpsTimeAction
} from './survey.actions';
import { StorageService } from '../../services/storage.service';
import { append, patch } from '@ngxs/store/operators';
import { LocalStorageEnum } from 'src/app/shared/enums/storage.enum';
import { ConfigService } from '../../services/config.service';
import { SurveyStorage } from 'src/app/shared/interfaces/storage.interface';
import { BookState } from '../book/book.state';
import { KibanaService } from '../../services/requests/kibana.service';
import { SessionState } from '../session/session.state';

export interface SurveyStateModel {
  maxTookNpsSurveyDurationInMinutes: number;
  alreadyTookNpsSurvey: boolean;
  timeTookNpsSurveyInSeconds: number;
  lessonsRegisterViewed: number[];
}

const SURVEY_STATE_TOKEN = new StateToken<SurveyStateModel>('survey');

@State<SurveyStateModel>({
  name: SURVEY_STATE_TOKEN,
  defaults: {
    alreadyTookNpsSurvey: false,
    maxTookNpsSurveyDurationInMinutes: 5,
    timeTookNpsSurveyInSeconds: 0,
    lessonsRegisterViewed: []
  }
})
@Injectable()
export class SurveyState implements NgxsOnInit, NgxsAfterBootstrap {
  constructor(
    private timerService: TimerService,
    private storageService: StorageService,
    private configService: ConfigService,
    private kibanaService: KibanaService,
    private store: Store
  ) {}

  ngxsOnInit({ patchState }: StateContext<SurveyStateModel>): void {
    const surveyNpsAnswers = this.storageService.get<SurveyStorage[]>(
      LocalStorageEnum.NpsSurvey
    );

    const hasAnswers = !!surveyNpsAnswers?.length;

    if (!hasAnswers) {
      return;
    }

    const portfolioId = this.store.selectSnapshot(BookState.bookId);

    const alreadyAnsweredNpsSurvey = surveyNpsAnswers?.some(
      (answer) =>
        answer.portfolioId === portfolioId && this.validateDomain(answer.domain)
    );

    patchState({
      alreadyTookNpsSurvey: alreadyAnsweredNpsSurvey
    });
  }

  ngxsAfterBootstrap({ patchState }: StateContext<SurveyStateModel>): void {
    const { maxTookNpsSurveyDurationInMinutes } =
      this.configService.config || {};

    if (maxTookNpsSurveyDurationInMinutes) {
      patchState({
        maxTookNpsSurveyDurationInMinutes
      });
    }
  }

  @Selector() static timeTookNpsSurvey({
    timeTookNpsSurveyInSeconds
  }: SurveyStateModel) {
    return timeTookNpsSurveyInSeconds;
  }

  @Selector() static alreadyTookNpsSurvey({
    alreadyTookNpsSurvey
  }: SurveyStateModel) {
    return alreadyTookNpsSurvey;
  }

  @Selector() static isReadyToStartNpsSurvey({
    timeTookNpsSurveyInSeconds,
    maxTookNpsSurveyDurationInMinutes,
    alreadyTookNpsSurvey
  }: SurveyStateModel): boolean {
    if (alreadyTookNpsSurvey) {
      return false;
    }

    const timeTookNpsSurveyInMinutes = timeTookNpsSurveyInSeconds / 60;

    const isTimeTookNpsSurveyGreaterThanMaxDuration =
      timeTookNpsSurveyInMinutes >= maxTookNpsSurveyDurationInMinutes;

    return isTimeTookNpsSurveyGreaterThanMaxDuration;
  }

  @Selector() static lessonsRegisterViewed({
    lessonsRegisterViewed
  }: SurveyStateModel) {
    return lessonsRegisterViewed;
  }

  @Action(StartTookNpsTimeAction)
  startTookNpsTimeAction(
    { patchState, getState }: StateContext<SurveyStateModel>,
    { maxDurationInMinutes }: StartTookNpsTimeAction
  ) {
    const { maxTookNpsSurveyDurationInMinutes } = getState();

    if (
      maxDurationInMinutes &&
      maxDurationInMinutes !== maxTookNpsSurveyDurationInMinutes
    ) {
      patchState({
        maxTookNpsSurveyDurationInMinutes: maxDurationInMinutes
      });
    }

    const internalInMilliseconds = 1000;
    const maxDurationInMilliseconds =
      (maxDurationInMinutes ?? maxTookNpsSurveyDurationInMinutes) * 60 * 1000;
    const delayInMilliseconds = 30 * 1000; // 30 seconds

    return this.timerService
      .startTimer(
        internalInMilliseconds,
        maxDurationInMilliseconds,
        delayInMilliseconds
      )
      .pipe(
        tap((time) => {
          patchState({
            timeTookNpsSurveyInSeconds: time
          });
        })
      );
  }

  @Action(SetLessonsRegisteredPagesViewedAction)
  setCountLessonsRegisteredPagesViewed(
    { setState, getState }: StateContext<SurveyStateModel>,
    { lessonId }: SetLessonsRegisteredPagesViewedAction
  ) {
    const { lessonsRegisterViewed } = getState();

    const isLessonAlreadyViewed = lessonsRegisterViewed.includes(lessonId);

    if (isLessonAlreadyViewed) {
      return;
    }

    setState(patch({ lessonsRegisterViewed: append([lessonId]) }));
  }

  @Action(SaveAnswerNpsSurveyAction) saveAnswerNpsSurveyAction(
    _: StateContext<SurveyStateModel>,
    { payload }: SaveAnswerNpsSurveyAction
  ) {
    const identifier = this.store.selectSnapshot(SessionState.identifier);

    if (!identifier) {
      console.warn('[SaveAnswerNpsSurveyAction]: Identifier not found.');
      return;
    }

    const { portfolioId, satisfaction, observation } = payload;

    return this.kibanaService
      .postKibanaLog({
        trackingId: 'nps-survey',
        guestIdentifier: identifier,
        objectData: {
          satisfaction,
          observation
        }
      })
      .pipe(
        tap(() => {
          const answers =
            this.storageService.get<SurveyStorage[]>(
              LocalStorageEnum.NpsSurvey
            ) || [];

          const answer = {
            portfolioId,
            satisfaction,
            domain: this.getDomain(),
            answedAt: new Date().toISOString()
          };

          const newAnswers = [...answers, answer];

          this.storageService.set<SurveyStorage[]>(
            LocalStorageEnum.NpsSurvey,
            newAnswers
          );
        })
      );
  }

  private getDomain(): string {
    if (typeof window !== 'undefined') {
      return window.location.hostname;
    }
    return '';
  }

  private validateDomain(domain: string): boolean {
    const currentDomain = this.getDomain();

    return currentDomain === domain;
  }
}
