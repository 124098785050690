import { AES, enc } from 'crypto-js';
import { environment as env } from 'src/environments/environment';

export function encrypt<T>(data: T): string {
  return AES.encrypt(JSON.stringify(data), env.cypherSecret).toString();
}

export function decrypt<T>(cypherText: string): T {
  if (typeof cypherText !== 'string') {
    throw new Error('Cyphertext must be a string');
  }

  const bytes = AES.decrypt(cypherText, env.cypherSecret);

  const plaintext: T = JSON.parse(bytes.toString(enc.Utf8));

  return plaintext;
}
