import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KibanaRegisterData } from '../../models/kibana.model';
import { LivSuccessResponse } from '../../models/liv-response-protocol.model';
import { ApiGatewayService } from '../api-gateway.service';

@Injectable({
  providedIn: 'root'
})
export class KibanaService {
  constructor(private apiGatewayService: ApiGatewayService) {}

  postKibanaLog(
    data: KibanaRegisterData
  ): Observable<LivSuccessResponse<HttpResponse<void>>> {
    return this.apiGatewayService.post<HttpResponse<void>>(
      `/custom/kibana`,
      data
    );
  }
}
